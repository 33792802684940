import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { logUserOut } from 'actions/auth';

const Logout = () => {
    useEffect(() => {
        logUserOut()
    }, []);
    return null;
};

export default withRouter(Logout);
