import { Toaster, ToastBar, toast as hotToast } from 'react-hot-toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTimes,
  faExclamationTriangle,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  crossButton: {
    position: 'absolute',
    top: 5,
    right: 5,
    background: 'none',
    border: 'none',
    color: 'rgba(0, 0, 0, 0.7)',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const Toast = () => {
  const classes = useStyles();

  return (
    <Toaster
      toastOptions={{
        duration: 4000,
        style: {
          border: '1px solid #5271FF',
          padding: '16px',
          fontWeight: '500',
          minWidth: 200,
        },
        success: {
          style: {
            border: '1px solid green',
          },
        },
        error: {
          style: {
            border: '1px solid red',
          },
        },
      }}
      position="top-right"
      reverseOrder={true}
    >
      {(t) => (
        <ToastBar toast={t}>
          {({ icon, message }) => (
            <div className={classes.container}>
              {icon}
              {message}
              <button
                className={classes.crossButton}
                onClick={() => hotToast.dismiss(t.id)}
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>
          )}
        </ToastBar>
      )}
    </Toaster>
  );
};

export const toast = {
  success: hotToast.success,
  error: hotToast.error,
  warn: (message) => {
    hotToast(message, {
      icon: (
        <FontAwesomeIcon
          style={{ width: 18, height: 18, color: '#FFCC1D' }}
          icon={faExclamationTriangle}
        />
      ),
    });
  },
  info: (message) => {
    hotToast(message, {
      icon: (
        <FontAwesomeIcon
          style={{ width: 18, height: 18, color: '#5271FF' }}
          icon={faInfoCircle}
        />
      ),
    });
  },
};

export default Toast;
