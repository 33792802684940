import {
  PROFILE_ERROR,
  PROFILE_SUCCESS,
  EDIT_PROFILE,
  RESET_PROFILE_FLAG,
  GET_ACCOUNT_PROFILE_SUCCESS,
  SAVE_FINANCIALS_DATA
} from "./actionTypes"

const initialState = {
  error: "",
  success: "",
  profile: {},
  financials: {}
}

const profile = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_PROFILE:
      state = { ...state }
      break
    case PROFILE_SUCCESS:
      state = { ...state, success: action.payload }
      break
    case PROFILE_ERROR:
      state = { ...state, error: action.payload }
      break
    case RESET_PROFILE_FLAG:
      state = { ...state, success: null }
      break
    case GET_ACCOUNT_PROFILE_SUCCESS:
      state = { ...state, profile: action.payload }
      break
    case SAVE_FINANCIALS_DATA:
      state = { ...state, financials: action.payload }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default profile
