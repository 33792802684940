// For getting data from the backend
// const baseUrl = process.env.REACT_APP_GUGU_URL || "http://localhost:5000";

const app_url = process.env.REACT_APP_URL || 'http://localhost:3000';
const blog_word_press =
  process.env.REACT_APP_WP_API_URL || 'http://34.250.111.120/graphql';
const oidc_url = process.env.REACT_APP_OIDC_URL || 'http://localhost:8080';
const accounts_url =
  process.env.REACT_APP_GUGU_ACCOUNTS_URL || 'http://localhost:8000/api';
const oidc_client_id = process.env.REACT_APP_CLIENT_ID || 'akiba';
const client_secret = process.env.REACT_APP_CLIENT_SECRET || 'secret';
const BANKING_TRANSACTION_YODLEE_SERVICE_URL =
  process.env.REACT_APP_BANKING_TRANSACTION_YODLEE_SERVICE_URL || '';
const chrome_extension_ui = process.env.REACT_APP_CHROME_EXTENSION_URL || '';

// rpa services
const rpa_url = process.env.REACT_APP_RPA_URL || '';
const web_seo_url = process.env.REACT_APP_WEB_SEO_URL || '';
const website_url = process.env.REACT_APP_WEB_URL || '';

// datalake services
const datalake_url = process.env.REACT_APP_DATALAKE_URL || '';

const react_demo_app_url = process.env.REACT_APP_URL;
const react_app_api_url = process.env.REACT_APP_API_URL;
const submit_loan_application_url = `${process.env.REACT_APP_SUBMIT_LOAN_APPICATION}/`;
const google_placement_key = process.env.REACT_APP_GOOGLE_PLACEMENT_KEY;

// For auth services
const OIDC_CONFIG = {
  authorization_endpoint: `${oidc_url}/auth`,
  token_endpoint: `${oidc_url}/token`,
  client_id: oidc_client_id,
  client_secret: client_secret,
  redirect_uri: `${app_url}/login/response`,
  post_logout_redirect_uri: `${app_url}/logout/response`,
  response_type: 'code',
  scope: 'openid',
  loadUserInfo: false,
  session_end_endpoint: `${oidc_url}/session/end`,
};

module.exports = {
  google: {
    API_KEY: '',
    CLIENT_ID: '',
    SECRET: '',
  },
  facebook: {
    APP_ID: '',
  },
  BANKING_TRANSACTION_YODLEE_SERVICE_URL,
  appUrl: app_url,
  baseUrl: process.env.REACT_APP_GUGU_URL || 'http://localhost:5000',
  rpaUrl: rpa_url,
  websiteUrl: website_url,
  datalakeUrl: datalake_url,
  oidcUrl: oidc_url,
  accountsUrl: accounts_url,
  verifiedRedirectUrl: `${app_url}/verified/response`,
  extension_url: chrome_extension_ui,
  blogRequestURL: blog_word_press,
  OIDC_CONFIG: {
    authorization_endpoint: `${oidc_url}/auth`,
    token_endpoint: `${oidc_url}/token`,
    client_id: oidc_client_id,
    client_secret: client_secret,
    redirect_uri: `${app_url}/login/response`,
    registration_redirect_uri: `${app_url}/registration/response`,
    post_logout_redirect_uri: `${app_url}/logout/response`,
    response_type: 'code',
    scope: 'openid',
    loadUserInfo: false,
    session_end_endpoint: `${oidc_url}/session/end`,
  },
  user_feedback: process.env.REACT_APP_USER_FEEDBACK,
  ReactDemoAppURL: `${react_demo_app_url}/login`,
  react_app_api_url,
  submit_loan_application_url,
  google_placement_key,
  web_seo_url,
};
