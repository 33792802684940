import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { getAuthenticationRequestUrl } from 'actions/auth';
import SuspenseLoader from './SuspenseLoader/SuspenseLoader';
// import ErrorPage from '../pages/Utility/pages-404';

const AppRoute = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  isLazy,
  useSkeleton,
  path,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      if (isAuthProtected && !localStorage.getItem('authUser')) {
        window.location.replace(getAuthenticationRequestUrl());
        return null;
      }
      if (isLazy) {
        if (path === '/onboarding') {
          return (
            <Suspense fallback={useSkeleton ? <SuspenseLoader /> : <></>}>
              <Component {...props} />
            </Suspense>
          );
        } else {
          return (
            <Suspense fallback={<></>}>
              <Layout>
                <Suspense fallback={useSkeleton ? <SuspenseLoader /> : <></>}>
                  <Component {...props} />
                </Suspense>
              </Layout>
            </Suspense>
          );
        }
      } else {
        return (
          <Layout>
            <Component {...props} />
          </Layout>
        );
      }
    }}
  />
);

AppRoute.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
  isLazy: PropTypes.bool,
};

export default AppRoute;
