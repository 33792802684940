import { takeLatest, takeEvery, fork, put, call, all } from "redux-saga/effects"
import { requestErrorHandler } from 'actions/helpers';
import { toast } from 'Toast';
// import { createBrowserHistory } from 'history';
// Login Redux States
import {
  EDIT_PROFILE,
  GET_ACCOUNT_PROFILE,
  UPDATE_ACCOUNT_PROFILE
} from "./actionTypes"
import {
  profileSuccess,
  profileError,
  getAccountProfile,
  getAccountProfileSuccess,
  saveFinancialsData
} from "./actions"
//Include Both Helper File with needed methods
import {
  postFakeProfile,
  postJwtProfile,
  getAccountProfileRequest,
  updateAccountProfileRequest,
  updateFinancialProfileRequest
} from "../../../helpers/fakebackend_helper"

// const history = createBrowserHistory();

function* editProfile({ payload: { user } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      const response = yield call(postJwtProfile, "/post-jwt-profile", {
        username: user.username,
        idx: user.idx,
      })
      yield put(profileSuccess(response))
    } else if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
      const response = yield call(postFakeProfile, {
        username: user.username,
        idx: user.idx,
      })
      yield put(profileSuccess(response))
    }
  } catch (error) {
    yield put(profileError(error))
  }
}

function* asyncGetAccountProfile() {
  try {
    const response = yield call(getAccountProfileRequest);
    if (response) {
      yield put(getAccountProfileSuccess(response))
    }
  } catch (error) {
  }
}

function* asyncUpdateAccountProfile({ payload }) {
  const { data, history } = payload
  // const financialPayload = {
  //   revenue_target: data?.revenue_target
  // }
  try {
    const response = yield call(updateAccountProfileRequest, data);
    // const financialResponse = yield call(updateFinancialProfileRequest, financialPayload);
    if (response) {
      yield put(getAccountProfile())
      history.push("/settings")
      toast.success("Your Business Details has been updated successfully.", { autoClose: 2000 });
    }
    // if (financialResponse && financialResponse.user_id) {
    //   yield put(saveFinancialsData(financialResponse))
    //   history.push("/settings")
    // }
  } catch (err) {
    const { message, alertTitle, alertType } = requestErrorHandler(err);
    toast.error(alertTitle, alertType, message);
  }
}


export function* watchProfile() {
  yield takeEvery(EDIT_PROFILE, editProfile)
}
export function* watchGetAccountProfile() {
  yield takeEvery(GET_ACCOUNT_PROFILE, asyncGetAccountProfile)
}
export function* watchUpdateAccountProfile() {
  yield takeLatest(UPDATE_ACCOUNT_PROFILE, asyncUpdateAccountProfile)
}

function* ProfileSaga() {
  yield all([fork(watchProfile)])
  yield all([fork(watchGetAccountProfile)])
  yield all([fork(watchUpdateAccountProfile)])
}

export default ProfileSaga;
