import { GET_LENDERS, GET_LENDERS_SUCCESS, GET_LENDERS_FAIL } from "./actionTypes"

const INIT_STATE = {
    lenders: [],
    error: {},
    isLoading: false,
}

const loan = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_LENDERS:
            return {
                ...state,
                lenders: [],
                isLoading: true,
            }
        case GET_LENDERS_SUCCESS:
            return {
                ...state,
                lenders: action.payload,
                isLoading: false,
            }
        case GET_LENDERS_FAIL:
            return {
                ...state,
                error: action.payload,
                lenders: [],
                isLoading: false,
            }
        default:
            return state
    }
}

export default loan
