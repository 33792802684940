import Skeleton from 'react-loading-skeleton';
import classes from "./SuspenseLoader.module.scss"

const SuspenseLoader = () => {
  return (
    <div className={classes.SuspenseLoader} >
      <Skeleton className={classes.Skeleton} count={3} />
    </div>
  );
};

export default SuspenseLoader;
