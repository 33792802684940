import { GET_LENDERS, GET_LENDERS_FAIL, GET_LENDERS_SUCCESS } from "./actionTypes"

export const getLenders = (payload) => ({
  type: GET_LENDERS,
  payload: payload
})

export const getLendersSuccess = data => ({
  type: GET_LENDERS_SUCCESS,
  payload: data,
})

export const getLendersFail = error => ({
  type: GET_LENDERS_FAIL,
  payload: error,
})