import { useState } from 'react';
import axios from 'axios';
import { checkLoanData } from '../components/CentralLocalStorage';
import { decode } from 'jsonwebtoken';
import { OIDC_CONFIG } from '../config';

export const logout = () => {
  localStorage.removeItem('state');
  localStorage.removeItem('authUser');
  return true;
};

export const getCurrentUserAuthToken = () => {
  return JSON.parse(localStorage.getItem('authUser'))?.access_token || null;
};

export const useUser = () => {
  const [user, setUser] = useState({
    isLoggedIn: JSON.parse(localStorage.getItem('authUser')) ? true : false,
    user: JSON.parse(localStorage.getItem('authUser')) || null,
  });

  const isLoggedIn = () => {
    return JSON.parse(localStorage.getItem('authUser')) ? true : false;
  };

  const refreshUserAction = () => {
    setUser({
      isLoggedIn: JSON.parse(localStorage.getItem('authUser')) ? true : false,
      user: JSON.parse(localStorage.getItem('authUser')) || null,
    });
  };

  return {
    user,
    isLoggedIn,
    refreshUserAction,
  };
};

export const isUserLoggedIn = () => {
  return JSON.parse(localStorage.getItem('authUser')) ? true : false;
};

export function getUserAuthHeader() {
  const user = JSON.parse(localStorage.getItem('authUser'));

  if (user && user.access_token) {
    return { Authorization: 'Bearer ' + user.access_token };
  } else {
    return {};
  }
}

export function getAccessToken() {
  const user = JSON.parse(localStorage.getItem('authUser'));

  if (user && user.access_token) {
    return user.access_token;
  } else {
    return undefined;
  }
}

export function getIdToken() {
  const user = JSON.parse(localStorage.getItem('authUser'));

  if (!user || !user.id_token) {
    logUserOut();
    return;
  }

  return user.id_token;
}

export const getUserDetails = () => {
  const user = JSON.parse(localStorage.getItem('authUser'));
  if (user) {
    return decode(user.id_token);
  } else {
    return undefined;
  }
};

export const getUserId = () => {
  const user = JSON.parse(localStorage.getItem('authUser'));
  if (user && user.id_token) {
    return decode(user.id_token).sub;
  } else {
    return undefined;
  }
};
// Authentication request after registration
export const getRegistrationAuthenticationRequestUrl = () => {
  let state = Math.random().toString(36).substring(7);
  localStorage.setItem('state', state);
  return `${OIDC_CONFIG.authorization_endpoint}?response_type=code&scope=${OIDC_CONFIG.scope}&client_id=${OIDC_CONFIG.client_id}&state=${state}&redirect_uri=${OIDC_CONFIG.registration_redirect_uri}`;
};

export const getAuthenticationRequestUrl = () => {
  const authUser = JSON.parse(localStorage.getItem('authUser'));

  if (authUser && authUser.id_token) {
    // we don't care about expiry.
    // it will automatically be checked
    // when req with token is made.
    const isLoanDataPresent = checkLoanData();
    if (isLoanDataPresent) {
      return '/loan';
    }
    return '/dashboard';
  } else {
    let state = Math.random().toString(36).substring(7);
    localStorage.setItem('state', state);
    return `${OIDC_CONFIG.authorization_endpoint}?response_type=code&scope=${OIDC_CONFIG.scope}&client_id=${OIDC_CONFIG.client_id}&state=${state}&redirect_uri=${OIDC_CONFIG.redirect_uri}`;
  }
};

export const getLogoutRequestUrl = () => {
  return `${OIDC_CONFIG.session_end_endpoint}?post_logout_redirect_uri=${OIDC_CONFIG.post_logout_redirect_uri}&client_id=${OIDC_CONFIG.client_id}`;
};

export const getAuthenticationRequestState = () => {
  return localStorage.getItem('state');
};

export const refreshToken = async () => {
  try {
    const authUser = JSON.parse(localStorage.getItem('authUser'));
    const refresh_token = authUser.refresh_token;

    if (!refresh_token) throw new Error('No Refresh Token Found.');

    let body = 'grant_type=refresh_token&';
    body += `client_id=${OIDC_CONFIG.client_id}&`;
    body += `client_secret=${OIDC_CONFIG.client_secret}&`;
    body += `refresh_token=${refresh_token}`;

    let res = await axios.post(OIDC_CONFIG.token_endpoint, body, {
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    if (res.data.id_token) {
      localStorage.setItem('authUser', JSON.stringify(res.data));
      const expirationDate = new Date(
        new Date().getTime() + res.data.expires_in * 1000
      );
      localStorage.setItem('expirationDate', expirationDate);

      return res.data.id_token;
    } else {
      throw Error('No ID Token Recieved.');
    }
  } catch (error) {
    console.log(error.response);
    return Promise.reject();
  }
};

export const logUserOut = () => {
  let walkThrough = { isShow: false, isTakeTour: false, step: 1 };
  localStorage.removeItem('authUser');
  localStorage.removeItem('expirationDate');
  localStorage.setItem('walkThrough', JSON.stringify(walkThrough));
  window.location.replace(getLogoutRequestUrl());
};

export const useCodeFlowLogin = () => {
  const [state, setState] = useState({
    data: null,
    isLoading: false,
    success: true,
  });

  const getToken = (user, code) => {
    let body = 'grant_type=authorization_code&';
    body += `code=${code}&`;
    body += `redirect_uri=${OIDC_CONFIG.redirect_uri}&`;
    body += `client_id=${OIDC_CONFIG.client_id}&`;
    body += `client_secret=${OIDC_CONFIG.client_secret}`;
    axios
      .post(OIDC_CONFIG.token_endpoint, body, {
        'Content-Type': 'application/x-www-form-urlencoded',
      })
      .then((res) => {
        if (res.status < 300) {
          if (res.data.access_token) {
            localStorage.setItem(user, JSON.stringify(res.data));
          }
          setState({
            ...state,
            data: res.data,
            isLoading: false,
            success: true,
          });
        } else {
          setState({ ...state, isLoading: false, success: false });
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(`Response status ${err.response?.status}`);
        } else {
          console.log(err);
        }
        setState({ ...state, isLoading: false, success: false });
      });
    setState({ ...state, isLoading: true });
  };

  return {
    state,
    getToken,
  };
};
