const checkLoanData = () => {
    const data = localStorage.getItem('loan-data')
    if (data) {
        return true
    } else {
        return false
    }
}
module.exports = {
    checkLoanData
}