import React, { useEffect, useState } from 'react';
import { useQuery, useIsMountedRef } from 'actions/helpers';
import axios from 'axios';
import { getIdToken } from '../../actions/auth';
import { Redirect,useLocation } from 'react-router-dom';
import { toast } from 'Toast';
import SpinnerLoader from './loader';

export const RegistrationStitchAuthenticationResponse = () => {
  const isMountedRef = useIsMountedRef();
  const [successFullyLinked, setSuccessFullyLinked] = useState(false);
  const query = useQuery();
  const code = query.get('code');
  const reauthorisationId = query.get('id');
  const location = useLocation();
  async function retrieveTokenUsingAuthorizationCode() {
    const id_token = await getIdToken();
    const headers = {
      Authorization: 'Bearer ' + id_token,
    };
    const assertion = await axios.get(
      `${process.env.REACT_APP_STITCH_URL}/getClientAssertion`,
      { headers: headers }
    );
    const body = {
      grant_type: 'authorization_code',
      client_id: `${process.env.REACT_APP_STITCH_CLIENT_ID}`,
      code: code,
      redirect_uri: `${process.env.REACT_APP_STITCH_REDIRECT_URL}`,
      client_assertion: assertion.data,
      code_verifier: localStorage.getItem('verifier'),
      client_assertion_type:
        'urn:ietf:params:oauth:client-assertion-type:jwt-bearer',
      audience: `${process.env.REACT_APP_STITCH_TOKEN_URL}`,
    };
    const bodyString = Object.entries(body)
      .map(([k, v]) => `${k}=${encodeURIComponent(v)}`)
      .join('&');
    const url = `${process.env.REACT_APP_STITCH_TOKEN_URL}`;
    const response = await fetch(url, {
      method: 'post',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: bodyString,
    });
    const responseBody = await response.json();
    if (responseBody.error) {
      setSuccessFullyLinked(true);
      toast.warn(
        'An error occurred when trying to link your account. Please try again'
      );
    }
    return responseBody;
  }
  async function postReauthorisationId(id){
    const url = `${process.env.REACT_APP_STITCH_URL}/authorization`;
    const content_type = 'application/json';
    const headers = {
      'Content-Type': content_type,
      'Access-Control-Allow-Origin': '*',
    };
      axios
        .post(url, id,{ headers: headers })
        .then((res) => {
          if (res.status == 200) {
            setSuccessFullyLinked(true);
            toast.success('Account Reauthorisation successfully');
            toast.info(
              'Your data is being processed and will be displayed in 10 mins.'
            );
          }
        })
        .catch((error) => {
            setSuccessFullyLinked(true);
            toast.error(error.message);
        });
    }
  async function postAccessToken(responseAccess) {
    const body = responseAccess;
    const url = `${process.env.REACT_APP_STITCH_URL}/pushTokens`;
    const id_token = await getIdToken();
    let data;
    if (id_token) {
      const headers = {
        Authorization: 'Bearer ' + id_token,
      };
      if (typeof body.access_token != 'undefined') {
        axios
          .post(url, body, { headers: headers })
          .then((res) => {
            data = res;
            if (res.status == 200) {
              setSuccessFullyLinked(true);
              toast.success('Account Linked successfully');
              toast.info(
                'Your data is being processed and will be displayed in 10 mins.'
              );
            }
          })
          .catch((error) => {
            setSuccessFullyLinked(true);
            toast.error(error.response.data.message);
        });
      }
    } else {
      setSuccessFullyLinked(true);
      toast.warn(
        'An error occurred when trying to link your account. Please try again'
      );
    }
    return data;
  }
  useEffect(() => {
    (async () => {
      if(code !== undefined && code !== null){
        const responseAccess = await retrieveTokenUsingAuthorizationCode();
        const bodyResponse = {
          access_token: responseAccess.access_token,
          expires_in: responseAccess.expires_in,
          id_token: responseAccess.id_token,
          refresh_token: responseAccess.refresh_token,
          scope: responseAccess.scope,
          token_type: responseAccess.token_type,
        };
        postAccessToken(bodyResponse);
      }
    })();
  }, []);

  useEffect (() => {
      if(location.search === ''){
        setSuccessFullyLinked(true);
        toast.warn(
          'An error occurred when trying to link your account. Please try again'
        );
        }
  }, [])

  useEffect (() => {
    (async () =>{
      if(reauthorisationId !== undefined &&  reauthorisationId !== null){
        const bodyResponse = {
          id: reauthorisationId,
        }
        postReauthorisationId(bodyResponse);
      }
    })();
  }, [])
  return successFullyLinked ? (
    <Redirect
      to={{
        pathname: '/dashboard',
      }}
    />
  ) : (
    <SpinnerLoader />
  );
};
