import {
  PROFILE_ERROR,
  PROFILE_SUCCESS,
  EDIT_PROFILE,
  RESET_PROFILE_FLAG,
  GET_ACCOUNT_PROFILE,
  GET_ACCOUNT_PROFILE_SUCCESS,
  UPDATE_ACCOUNT_PROFILE,
  SAVE_FINANCIALS_DATA
} from "./actionTypes"

export const editProfile = user => {
  return {
    type: EDIT_PROFILE,
    payload: { user },
  }
}
export const profileSuccess = msg => {
  return {
    type: PROFILE_SUCCESS,
    payload: msg,
  }
}
export const profileError = error => {
  return {
    type: PROFILE_ERROR,
    payload: error,
  }
}
export const resetProfileFlag = error => {
  return {
    type: RESET_PROFILE_FLAG,
  }
}
export const getAccountProfile = () => {
  return {
    type: GET_ACCOUNT_PROFILE
  }
}
export const getAccountProfileSuccess = (data) => {
  return {
    type: GET_ACCOUNT_PROFILE_SUCCESS,
    payload: data
  }
}
export const updateAccountProfile = (data, history) => {
  return {
    type: UPDATE_ACCOUNT_PROFILE,
    payload: { data, history }
  }
}
export const saveFinancialsData = (data) => {
  return {
    type: SAVE_FINANCIALS_DATA,
    payload: data
  }
}
