import React, { useEffect, useRef, useState } from 'react';
import { getAuthenticationRequestState } from 'actions/auth';
import { useQuery, useIsMountedRef } from 'actions/helpers';
import { OIDC_CONFIG } from 'config';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import { checkLoanData } from '../../components/CentralLocalStorage';

// TODO: reuse code for the two elements

const DetermineRedirectionUrl = (check) => {
  const isLoanDataPresent = checkLoanData();
  if (isLoanDataPresent) {
    return '/loan';
  } else if (check === 'checkAfterLogin') {
    return '/dashboard';
  } else if (check === 'checkAfterRegister') {
    return '/onboarding';
  }
};

export const RegistrationAuthenticationResponse = () => {
  const isMountedRef = useIsMountedRef();

  const [token, setToken] = useState({
    data: null,
    isLoading: false,
    success: true,
  });

  const query = useQuery();
  const code = query.get('code');
  const state = query.get('state');
  const error = query.get('error') || null;

  useEffect(() => {
    if (isMountedRef.current) {
      let body = 'grant_type=authorization_code&';
      body += `code=${code}&`;
      body += `redirect_uri=${OIDC_CONFIG.registration_redirect_uri}&`;
      body += `client_id=${OIDC_CONFIG.client_id}&`;
      body += `client_secret=${OIDC_CONFIG.client_secret}`;
      axios
        .post(OIDC_CONFIG.token_endpoint, body, {
          'Content-Type': 'application/x-www-form-urlencoded',
        })
        .then((res) => {
          if (res.status < 300) {
            if (res.data.access_token) {
              localStorage.setItem('authUser', JSON.stringify(res.data));
              const expirationDate = new Date(
                new Date().getTime() + res.data.expires_in * 1000
              );
              localStorage.setItem('expirationDate', expirationDate);
            }
            setToken({
              ...token,
              data: res.data,
              isLoading: false,
              success: true,
            });
          } else {
            setToken({ ...token, isLoading: false, success: false });
          }
        })
        .catch((err) => {
          if (err.response) {
            console.log(`Response status ${err.response.status}`);
          } else {
            console.log(err);
          }
          setToken({ ...token, isLoading: false, success: false });
        });
      setToken({ ...token, isLoading: true });
    }
  }, [isMountedRef.current]);

  if (error) {
    return <Redirect to="/error" />;
  } else if (state !== getAuthenticationRequestState()) {
    return <Redirect to="/error" />;
  }

  return token && token.data !== null && token.success ? (
    <Redirect to={DetermineRedirectionUrl('checkAfterRegister')} />
  ) : token.success ? (
    <div>
      <h1>Logging you in....</h1>
    </div>
  ) : (
    <Redirect to="/error" />
  );
};

export function AuthenticationResponse() {
  const isMounted = useRef(false);
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const [token, setToken] = useState({
    data: null,
    isLoading: false,
    success: true,
  });

  const query = useQuery();
  const code = query.get('code');
  const state = query.get('state');
  const error = query.get('error') || null;

  useEffect(() => {
  // Prevent multiple /token endpoint calls
  if (isMounted.current && token.data == null && !token.isLoading) {
      // cancel token
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      let body = 'grant_type=authorization_code&';
      body += `code=${code}&`;
      body += `redirect_uri=${OIDC_CONFIG.redirect_uri}&`;
      body += `client_id=${OIDC_CONFIG.client_id}&`;
      body += `client_secret=${OIDC_CONFIG.client_secret}`;
      axios
        .post(OIDC_CONFIG.token_endpoint, body, {
          'Content-Type': 'application/x-www-form-urlencoded',
          cancelToken: source.token,
        })
        .then((res) => {
          if (res.status < 300) {
            if (res.data.access_token) {
              let walkThrough = JSON.parse(localStorage.getItem('walkThrough'));
              if (walkThrough) {
                localStorage.setItem(
                  'walkThrough',
                  JSON.stringify(walkThrough)
                );
              } else {
                localStorage.setItem(
                  'walkThrough',
                  JSON.stringify({ isShow: true, isTakeTour: false, step: 1 })
                );
              }
              localStorage.setItem('authUser', JSON.stringify(res.data));
              const expirationDate = new Date(
                new Date().getTime() + res.data.expires_in * 1000
              );
              localStorage.setItem('expirationDate', expirationDate);
            }
            if (isMounted.current) {
              setToken({
                ...token,
                data: res.data,
                isLoading: false,
                success: true,
              });
            }
          } else {
            if (isMounted.current) {
              setToken({ ...token, isLoading: false, success: false });
            }
          }
        })
        .catch((err) => {
          if (err.response) {
            console.log(`Response status ${err.response.status}`);
          } else {
            console.log(err);
          }

          if (isMounted.current) {
            setToken({ ...token, isLoading: false, success: false });
          }
        });
      if (isMounted.current) {
        setToken({ ...token, isLoading: true });
      }
    }
  }, [isMounted.current]);

  if (error) {
    return <Redirect to="/error" />;
  } else if (state !== getAuthenticationRequestState()) {
    return <Redirect to="/error-state" />;
  }

  return token && token.data !== null && token.success ? (
    <Redirect to={DetermineRedirectionUrl('checkAfterLogin')} />
  ) : token.success ? (
    <div>
      <h1>Logging you in....</h1>
    </div>
  ) : (
    <Redirect to="/error-man" />
  );
}
