import React from "react"

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert"

export const renderAlert = (props) => {
  switch (props.alertState.alertType) {
    case "basic":
      return (
        <SweetAlert
          title={props.alertState.title}
          onConfirm={() => props.handleRemoveAlert()}
        >
          {props.alertState.message}
        </SweetAlert>
      );
    case "error":
      return (
        <SweetAlert
          error={true}
          title={props.alertState.title}
          onConfirm={() => props.handleRemoveAlert()}
        >
          {props.alertState.message}
        </SweetAlert>
      )
    case "success":
      return (
        <SweetAlert
          success={true}
          title={props.alertState.title}
          onConfirm={() => props.handleRemoveAlert()}
        >
          {props.alertState.message}
        </SweetAlert>
      )
    case "done":
      return (
        <SweetAlert
          title={props.alertState.title}
          timeout={2000}
          style={{
            position: "absolute",
            top: "0px",
            right: "0px",
          }}
          showCloseButton={false}
          showConfirm={false}
          success={true}
          onConfirm={() => props.handleRemoveAlert()}
        ></SweetAlert>
      )
    default:
      return null;
  }
}

