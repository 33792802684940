import * as React from "react";
import { createContext, useReducer, useContext } from "react";

const init = { title: "", messages: [], alert: false, alertType: "error" };

export const alertReducer = (state, action) => {
    switch (action.type) {
        case "SET_ALERT":
            return {
                ...action
            }
        case "REMOVE_ALERT":
            return {
                ...init
            }
        default:
            return {
                ...state
            }
    }
}

// ================== CONTEXT =========================

export const AlertContext = createContext([]);

export const AlertContextProvider = (props) => {
    const [alertState, alertDispatch] = useReducer(alertReducer, init );
    return (
        <AlertContext.Provider value={{alertState, alertDispatch}}>
            {props.children}
        </AlertContext.Provider>
    );
}
export const useAlertContext = () => useContext(AlertContext);

export function useAlert() {
    const {alertState, alertDispatch} = useContext(AlertContext);

    const handleRemoveAlert = () => {
        alertDispatch({
            type: "REMOVE_ALERT"
        });
    }

    const handleAddAlertMessage = (message, alertType) => {
        alertDispatch({
            type: "SET_ALERT",
            ...alertState,
            alert: true,
            alertType: alertType,
            message: message
        })
    }

    const handleChangeAlertTitle = (title, alertType) => {
        alertDispatch({
            type: "SET_ALERT",
            ...alertState,
            alert: true,
            alertType: alertType,
            title
        })
    }

    const handleAlerts = (title, alertType, message) => {
        alertDispatch({
            type: "SET_ALERT",
            title,
            message,
            alertType,
            alert: true,
        })
    }

    const handleAlert = (title, alertType, message) => {
        alertDispatch({
            type: "SET_ALERT",
            title,
            alertType,
            message: message,
            alert: true
        })
    }

    return {
        alertState,
        handleAddAlertMessage,
        handleChangeAlertTitle,
        handleAlert,
        handleAlerts,
        handleRemoveAlert
    }
}