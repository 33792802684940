import React, { useEffect, useState } from 'react';

import * as serviceWorkerRegistration from '../../serviceWorkerRegistration';
import * as classes from './AppNewVersionPopup.module.sass';

const AppNewVersionPopup = () => {
  const [showReload, setShowReload] = useState(false);
  const [waitingWorker, setWaitingWorker] = useState(null);

  const onSWUpdate = (registration) => {
    setShowReload(true);
    localStorage.setItem('newVerAvailable', true);
    setWaitingWorker(registration.waiting);
  };

  const loadStateFromLocalStorage = () => {
    const newVerAvailable = JSON.parse(localStorage.getItem('newVerAvailable'));
    if (newVerAvailable) {
      setShowReload(true);
    }
  };

  useEffect(() => {
    loadStateFromLocalStorage();
    serviceWorkerRegistration.register({ onUpdate: onSWUpdate });
  }, []);

  const refresh = () => {
    waitingWorker?.postMessage({ type: 'SKIP_WAITING' });
    setShowReload(false);
    localStorage.removeItem('newVerAvailable');
    window.location.reload();
  };

  if (!showReload) return null;
  return (
    <div className={classes.AppNewVersionPopup}>
      <div className={classes.update__title}>New update is available</div>
      <div className={classes.update__desc} onClick={refresh}>
        Click to refresh
      </div>
    </div>
  );
};

export default AppNewVersionPopup;
