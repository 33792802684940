export const EDIT_PROFILE = "EDIT_PROFILE"
export const PROFILE_SUCCESS = "PROFILE_SUCCESS"
export const PROFILE_ERROR = "PROFILE_ERROR"
export const RESET_PROFILE_FLAG = "RESET_PROFILE_FLAG";
export const GET_ACCOUNT_PROFILE = "GET_ACCOUNT_PROFILE";
export const GET_ACCOUNT_PROFILE_SUCCESS = "GET_ACCOUNT_PROFILE_SUCCESS";
export const GET_ACCOUNT_PROFILE_FAILURE = "GET_ACCOUNT_PROFILE_FAILURE";
export const UPDATE_ACCOUNT_PROFILE = "UPDATE_ACCOUNT_PROFILE";
export const UPDATE_ACCOUNT_PROFILE_SUCCESS = "UPDATE_ACCOUNT_PROFILE_SUCCESS";
export const SAVE_FINANCIALS_DATA = "SAVE_FINANCIALS_DATA";
