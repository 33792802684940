import React, { Component, lazy, useEffect } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
// Import Routes
import { authProtectedRoutes, publicRoutes } from './routes';
import AppRoute from './routes/route';

// layouts
const VerticalLayout = lazy(() => import('./components/VerticalLayout'));
import NonAuthLayout from './components/NonAuthLayout';

// Import scss
import './assets/scss/theme.scss';

// Import Firebase Configuration file
// import { initFirebaseBackend } from './helpers/firebase_helper'

// Import fackbackend Configuration file
import { useAlert } from 'actions/alerts';
import { renderAlert } from 'pages/UiComponent/UiSweetAlert';
import AppNewVersionPopup from 'components/AppNewVersionPopup/AppNewVersionPopup';
import ErrorBoundary from './ErrorBoundary';

const AlertUi = () => {
  const alert = useAlert();

  return alert.alertState.alert ? renderAlert(alert) : null;
};

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.getLayout = this.getLayout.bind(this);
  }

  /**
   * Returns the layout
   */
  getLayout = () => {
    let layoutCls = VerticalLayout;

    switch (this.props.layout.layoutType) {
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  };

  render() {
    const Layout = this.getLayout();

    return (
      <ErrorBoundary>
        <React.Fragment>
          <AlertUi />
          <Router>
            <Switch>
              {publicRoutes.map((route, idx) => (
                <AppRoute
                  path={route.path}
                  layout={NonAuthLayout}
                  component={route.component}
                  isLazy={route.isLazy}
                  key={route.path}
                  isAuthProtected={false}
                  useSkeleton={route.useSkeleton}
                  exact
                />
              ))}
              {authProtectedRoutes.map((route, idx) => (
                <AppRoute
                  path={route.path}
                  layout={Layout}
                  component={route.component}
                  isLazy={route.isLazy}
                  key={route.path}
                  isAuthProtected={true}
                  useSkeleton={route.useSkeleton}
                  exact
                />
              ))}
            </Switch>
          </Router>
          <AppNewVersionPopup />
        </React.Fragment>
      </ErrorBoundary>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    layout: state.Layout,
  };
};

App.propTypes = {
  layout: PropTypes.object,
};

export default connect(mapStateToProps, null)(App);
