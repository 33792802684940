import { GET_TASKS_SUCCESS, GET_TASKS_FAIL, SET_WALK_THROUGH } from "./actionTypes"

const getWalkThroughSteps = () => {
  let walkThrough = JSON.parse(localStorage.getItem("walkThrough"))
  let data = {
    isShow: walkThrough?.isShow ?? false, 
    isTakeTour: walkThrough?.isTakeTour ?? false, 
    step: walkThrough?.step ?? 1
  }
  return data;
}

const INIT_STATE = {
  tasks: [],
  error: {},
  walkThroughSteps: getWalkThroughSteps() || {isShow: false, isTakeTour: false, step: 1}
}

const tasks = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_TASKS_SUCCESS:
      return {
        ...state,
        tasks: action.payload,
      }

    case GET_TASKS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case SET_WALK_THROUGH:
      return {
        ...state,
        walkThroughSteps: action.payload,
      }

    default:
      return state
  }
}

export default tasks
