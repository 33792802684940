import { useLocation } from 'react-router-dom'
import { ReactDemoAppURL } from '../config'
function setToLS(data) {
    if (data) {
        localStorage.setItem('loan-data', data)
    } else {
        localStorage.removeItem('loan-data')
    }
    window.location.replace(ReactDemoAppURL)
}
const StoreLoanDataToLS = () => {

    let location = useLocation();
    // Fix: params values that are being cut (the full object being sent wasn't showing with const data = query.get("loan-data");)
    // the useQuery package might be the issue. Don't use library
    const querystrings = location.search
    const loanDataString = querystrings.split('=')[1]; // only one query param is being sent. Adjust this when this changes
    const loanDataObject = decodeURIComponent(loanDataString);

    setToLS(loanDataObject)

    return null
}
export default StoreLoanDataToLS