import * as React from 'react';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const usePagination = (args = { limit: 20, page: 1, count: 100 }) => {
  const [pagination, setPagination] = useState({ ...args });

  const paginationAction = (limit = 20, page = 1, count = 100) => {
    setPagination({ limit: limit, page: page, count: count });
  };

  const setLimit = (limit) => setPagination({ ...pagination, limit });

  const setPage = (page) => setPagination({ ...pagination, page });

  const setCount = (count) => setPagination({ ...pagination, count });

  const nextPage = () =>
    setPagination({ ...pagination, page: pagination.page + 1 });

  const prevPage = () =>
    setPagination({
      ...pagination,
      page: pagination.page > 1 ? pagination.page - 1 : 1,
    });

  const skipPage = (skip) =>
    setPagination({
      ...pagination,
      page: pagination.page + skip > 1 ? pagination.page + skip : 1,
    });

  return {
    page: pagination.page,
    limit: pagination.limit,
    count: pagination.count,
    pagination,
    setLimit,
    setPage,
    setCount,
    nextPage,
    prevPage,
    skipPage,
    paginationAction,
  };
};

export function useIsMountedRef() {
  const isMountedRef = React.useRef(null);
  useEffect(() => {
    isMountedRef.current = true;
    return () => (isMountedRef.current = false);
  });
  return isMountedRef;
}

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const delay = (t) => new Promise((resolve) => setTimeout(resolve, t));

function base64UrlEncode(byteArray) {
  const charCodes = String.fromCharCode(...byteArray);
  return window
    .btoa(charCodes)
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '');
}

export function requestErrorHandler(err, isRegisterError) {
  let message = 'Request Failed';
  switch (err.response?.status || 500) {
    case 400:
      message =
        err.response.data.message || 'The information you provided is invalid';
      break;
    case 409:
      message =
        err.response.data.message ||
        'The information you provided already exists';
      break;
    case 412:
      const { data } = err.response.data;
      let details = '';
      Object.values(data.errors).forEach((e) => {
        details += e[0] + ' \n';
      });
      message = details;
      break;
    case 404:
      message =
        err.response.data.message ||
        'The information you provided was not found';
      break;
    case 500:
      message =
        err.response?.data.message ||
        (isRegisterError
          ? 'An error occured while trying to register. Please try again later.'
          : 'An unknown error occured.');
      break;
    default:
      message = 'Something went wrong';
  }

  return {
    message: message,
    alertType: 'error',
    alertTitle: 'Error',
  };
}

export function useCountDown(init) {
  const [isActive, setIsActive] = useState(false);
  const [seconds, setSeconds] = useState(init);

  useEffect(() => {
    let interval = null;
    if (isActive && seconds !== 0) {
      interval = setInterval(() => {
        setSeconds((sec) => seconds - 1);
      }, 1000);
    } else if (!isActive && seconds !== init) {
      clearInterval(interval);
    } else if (seconds === 0) {
      setIsActive(false);
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isActive, seconds]);

  function reset() {
    setSeconds(init);
    setIsActive(false);
  }

  function start() {
    setIsActive(true);
  }

  function stop() {
    setIsActive(false);
  }

  return {
    isActive,
    seconds,
    start,
    stop,
    reset,
  };
}
