import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
// Not lazy loading Onboarding because for some reason it
// has scss which is being used globally.
import {
  getAuthenticationRequestUrl,
  getRegistrationAuthenticationRequestUrl,
} from 'actions/auth';
// Authentication related pages
import Logout from '../pages/Authentication/Logout';
import {
  AuthenticationResponse,
  RegistrationAuthenticationResponse,
} from 'pages/AuthenticationInner/AuthenticationResponse';
import { RegistrationStitchAuthenticationResponse } from 'pages/AuthenticationInner/stitch-auth-response';

const UpLoadBankStatement = lazy(() => import('../pages/BankAccounts/index'));
const LoanCalculator = lazy(() =>
  import('../pages/Onboarding/form/LoanCalculator/Index')
);
const LoanSuccess = lazy(() =>
  import('../pages/Onboarding/form/LoanCalculator/LoanSuccess')
);
const LoanCalculatorSumery = lazy(() =>
  import('../pages/Onboarding/form/LoanCalculator/Summery')
);

const LoanCalculatorHome = lazy(() =>
  import('../pages/Onboarding/form/LoanCalculator/LoanCalculatorHome')
);

const BankAccount = lazy(() => import('../pages/NewDashboard/BankAccounts'));

//Settings
const Setting = lazy(() => import('../pages/Settings/index'));
const LegalAbout = lazy(() => import('../pages/LegalAbout/index'));
const PersonalDetails = lazy(() => import('../pages/Settings/PersonalDetails'));
const BusinessDetails = lazy(() => import('../pages/Settings/BusinessDetails'));
const UpdatePassword = lazy(() => import('../pages/Settings/UpdatePassword'));
const TermsConditions = lazy(() => import('../pages/LegalAbout/PrivacyPoilcy'));
const Documents = lazy(() => import('../pages/Settings/Documents'));
const AboutUs = lazy(() => import('../pages/LegalAbout/AboutUs'));
const Notifications = lazy(() => import('../pages/Settings/Notifications'));
const ConnectPos = lazy(() => import('../pages/SelectPOS'));
const ListBankAccounts = lazy(() =>
  import('../pages/BankAccounts/ListBankAccounts')
);
const DocumentsDetails = lazy(() =>
  import('../pages/Settings/DocumentsDetails')
);

const BankAccounts = lazy(() => import('../pages/BankAccounts/index'));

const Profile = lazy(() => import('../pages/AccountProfile/index'));
const PosAccounts = lazy(() => import('../pages/POSAccounts/PaymentsForm'));

const ForgetPwd = lazy(() => import('../pages/Authentication/ForgetPassword'));
const ChangePwd = lazy(() => import('../pages/Authentication/ChangePassword'));
const EmailVerification = lazy(() =>
  import('../pages/AuthenticationInner/auth-email-verification')
);
const ChangePasswordVerification = lazy(() =>
  import('../pages/AuthenticationInner/auth-change-password-verification')
);

// New Dashboard
const NewDashboard = lazy(() => import('../pages/NewDashboard/dashboard'));
const AddLoan = lazy(() => import('../pages/NewDashboard/AddLoan/AddLoan'));
// import EmptyDashboard from '../pages/EmptyDashboard'; // We want to use it if user has empty dashboard

// Cash Flow Table
const CashFlow = lazy(() => import('../pages/CashFlow'));

//Scores
const DetailedScore = lazy(() => import('../pages/Detailed-score/index'));

// Loan Application
const LoanApplication = lazy(() => import('../pages/LoanApplication/index'));

// Pages
const PagesMaintenance = lazy(() =>
  import('../pages/Utility/pages-maintenance')
);
const PagesComingsoon = lazy(() => import('../pages/Utility/pages-comingsoon'));
const Pages404 = lazy(() => import('../pages/Utility/pages-404'));
const Review = lazy(() => import('../pages/Rating/Review'));
const Referrals = lazy(() => import('../pages/Referrals/Referrals'));
const AccountSettings = lazy(() =>
  import('../pages/AccountSettings/AccountSettings')
);
const ReviewResult = lazy(() => import('../pages/ReviewResult/ReviewResult'));
const LoanOffer = lazy(() => import('../pages/LoanOffer/LoanOffer'));
// import Pages500 from '../pages/Utility/pages-500'; // Not deleting, might come useful
import StoreLoanDataToLS from '../components/StoreLoanDataToLS';

const Unsubscribe = lazy(() => import('../pages/Unsubscribe/index'));
const UnsubscribeThank = lazy(() =>
  import('../pages/Unsubscribe/Thanks/index')
);
const UnsubscribeSuccess = lazy(() =>
  import('../pages/Unsubscribe/Success/index')
);
// import Pages500 from '../pages/Utility/pages-500'; // Not deleting, might come useful

// Onboarding
const Onboarding = lazy(() => import('../pages/Onboarding/index'));
const ManuallyUploadPOSStatement = lazy(() => import('../pages/POSStatement'));

const Login = () => {
  const url = getAuthenticationRequestUrl();

  if (url !== '/dashboard') {
    window.location.replace(getAuthenticationRequestUrl());
    return null;
  }

  return <Redirect to={url} />;
};

const Register = () => {
  const url = getRegistrationAuthenticationRequestUrl();

  if (url !== '/dashboard') {
    window.location.replace(getRegistrationAuthenticationRequestUrl());
    return null;
  }

  return <Redirect to={url} />;
};

const authProtectedRoutes = [
  {
    path: '/dashboard',
    component: NewDashboard,
    isLazy: true,
    useSkeleton: true,
  },
  {
    path: '/add-loan',
    component: AddLoan,
    isLazy: true,
    useSkeleton: true,
  },
  { path: '/cash-flow', component: CashFlow, isLazy: true, useSkeleton: true },
  {
    path: '/detailed-score',
    component: DetailedScore,
    isLazy: true,
    useSkeleton: true,
  },
  {
    path: '/apply',
    component: LoanApplication,
    isLazy: true,
    useSkeleton: true,
  },
  {
    path: '/request-vetting',
    component: Referrals,
    isLazy: true,
    useSkeleton: true,
  },
  {
    path: '/social-score',
    component: ReviewResult,
    isLazy: true,
    useSkeleton: true,
  },
  {
    path: '/list-accounts',
    component: ListBankAccounts,
    isLazy: true,
    useSkeleton: true,
  },
  {
    path: '/account-plan',
    component: AccountSettings,
    isLazy: true,
    useSkeleton: true,
  },
  {
    path: '/funding-offer',
    component: LoanOffer,
    isLazy: true,
    useSkeleton: true,
  },
  {
    path: '/connect-accounts',
    component: UpLoadBankStatement,
    isLazy: true,
    useSkeleton: true,
  },
  {
    path: '/loan-calculator',
    component: LoanCalculatorHome,
    isLazy: true,
    useSkeleton: true,
  },
  {
    path: '/loan',
    component: LoanCalculator,
    isLazy: true,
    useSkeleton: true,
  },
  {
    path: '/loan-success',
    component: LoanSuccess,
    isLazy: true,
    useSkeleton: true,
  },
  {
    path: '/summary',
    component: LoanCalculatorSumery,
    isLazy: true,
    useSkeleton: true,
  },
  { path: '/profile', component: Profile, isLazy: true, useSkeleton: true },
  // {path: "/community",component: Chat},
  // {path: "/",exact: true,component: () => <Redirect to="/dashboard" />},
  {
    path: '/onboarding',
    component: Onboarding,
    isLazy: true,
    useSkeleton: true,
  },
  {
    path: '/settings',
    component: Setting,
    isLazy: true,
    useSkeleton: true,
  },
  {
    path: '/legal',
    component: LegalAbout,
    isLazy: true,
    useSkeleton: true,
  },
  {
    // path: '/settings/personal-Details',
    path: '/settings/PersonalDetails',
    component: PersonalDetails,
    isLazy: true,
    useSkeleton: true,
  },
  {
    path: '/settings/BusinessDetails',
    component: BusinessDetails,
    isLazy: true,
    useSkeleton: true,
  },
  {
    path: '/settings/UpdatePassword',
    component: UpdatePassword,
    isLazy: true,
    useSkeleton: true,
  },
  {
    path: '/settings/TermsConditions',
    component: TermsConditions,
    isLazy: true,
    useSkeleton: true,
  },
  {
    path: '/settings/Documents',
    component: Documents,
    isLazy: true,
    useSkeleton: true,
  },
  {
    path: '/settings/AboutUs',
    component: AboutUs,
    isLazy: true,
    useSkeleton: true,
  },
  {
    path: '/settings/Notifications',
    component: Notifications,
    isLazy: true,
    useSkeleton: true,
  },
  {
    path: '/settings/select-pos-accounts',
    component: ConnectPos,
    isLazy: true,
    useSkeleton: true,
  },
  {
    path: '/settings/Documents/:id',
    component: DocumentsDetails,
    isLazy: true,
    useSkeleton: true,
  },
  {
    path: '/connect-pos',
    component: PosAccounts,
    isLazy: true,
    useSkeleton: true,
  },
  {
    path: 'settings/connect-pos',
    component: PosAccounts,
    isLazy: true,
    useSkeleton: true,
  },
  {
    path: '/summery',
    component: LoanCalculatorSumery,
    isLazy: true,
    useSkeleton: true,
  },
  {
    path: '/update-bank-accounts',
    component: BankAccount,
    isLazy: true,
    useSkeleton: true,
  },
  {
    path: '/settings/select-pos-accounts/manually-upload-pos-statement',
    component: ManuallyUploadPOSStatement,
    isLazy: true,
    useSkeleton: true,
  },
];

const publicRoutes = [
  // { path: '/test', component: CardPricing },
  { path: '/login/response', component: AuthenticationResponse },
  {
    path: '/registration/response',
    component: RegistrationAuthenticationResponse,
    isLazy: true,
  },
  { path: '/return', component: RegistrationStitchAuthenticationResponse },
  { path: '/logout/response', component: () => <Redirect to="/login" /> },
  { path: '/logout', component: Logout },
  { path: '/forgot-password', component: ForgetPwd, isLazy: true },
  { path: '/change-password', component: ChangePwd, isLazy: true },
  { path: '/review', component: Review, isLazy: true },
  { path: '/pages-maintenance', component: PagesMaintenance, isLazy: true },
  { path: '/pages-comingsoon', component: PagesComingsoon, isLazy: true },
  { path: '/unsubscribe/:id', component: Unsubscribe, isLazy: true },
  { path: '/unsubscribe-thanks', component: UnsubscribeThank, isLazy: true },
  { path: '/unsubscribe-success', component: UnsubscribeSuccess, isLazy: true },
  { path: '/get-loan-data', component: StoreLoanDataToLS },
  { path: '/pages-404', component: Pages404, isLazy: true },
  { path: '/login', component: Login },
  { path: '/register', component: Register },
  { path: '/', exact: true, component: () => <Redirect to="/dashboard" /> },
  {
    path: '/error',
    exact: true,
    component: () => <Redirect to="/pages-404" />,
  },

  {
    path: '/auth-email-verification',
    component: EmailVerification,
    isLazy: true,
  },
  {
    path: '/auth-change-password-verification',
    component: ChangePasswordVerification,
    isLazy: true,
    useSkeleton: true,
  },
];

export { authProtectedRoutes, publicRoutes };
