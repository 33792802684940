import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_LENDERS } from "./actionTypes"
import { getLendersSuccess, getLendersFail } from "./actions"

//Include Both Helper File with needed methods
import { getLenders } from "helpers/fakebackend_helper"

function* fetchLenders({payload}) {
    try {
        const response = yield call(getLenders, payload)
        yield put(getLendersSuccess(response))
    } catch (error) {
        yield put(getLendersFail(error))
    }
}

function* loanSaga() {
    yield takeEvery(GET_LENDERS, fetchLenders)
}

export default loanSaga
